import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1151.000000 1151.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1151.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M4500 7469 c0 -50 480 -898 678 -1198 42 -63 30 -71 129 93 76 126
78 109 -28 236 -66 80 -202 277 -262 381 -54 94 -136 268 -177 375 l-43 111
-60 7 c-101 10 -237 7 -237 -5z"
          />
          <path
            d="M5040 7464 c0 -24 90 -199 253 -489 24 -44 57 -102 72 -130 90 -161
341 -591 461 -789 l71 -119 -44 -76 c-46 -77 -80 -121 -94 -121 -5 0 -69 102
-143 226 l-134 226 -43 -54 c-39 -49 -99 -163 -99 -188 0 -6 29 -44 64 -84
121 -139 224 -330 266 -496 6 -25 27 -73 46 -107 46 -84 64 -83 107 2 36 71
100 179 204 340 145 224 164 275 143 367 -17 73 -27 90 -240 448 -311 524
-440 754 -428 765 2 2 33 -5 68 -17 91 -30 263 -30 374 0 44 12 81 20 84 18 2
-3 -10 -31 -28 -63 -64 -113 -70 -128 -70 -157 0 -34 56 -141 111 -209 l38
-48 14 23 c8 13 74 129 147 258 73 129 149 264 170 300 77 134 99 178 93 184
-4 3 -334 6 -735 6 -656 0 -728 -2 -728 -16z"
          />
          <path
            d="M6345 6895 c-14 -13 -25 -33 -25 -44 0 -22 53 -71 78 -71 9 0 58 -21
110 -46 78 -37 109 -60 176 -127 141 -140 189 -266 187 -490 0 -114 2 -130 19
-147 27 -27 53 -25 87 4 l28 24 0 154 c-1 146 -2 157 -33 248 -84 243 -257
417 -499 501 -74 25 -99 24 -128 -6z"
          />
          <path
            d="M6285 6676 c-48 -48 -25 -94 65 -124 118 -40 211 -125 263 -241 16
-36 22 -73 25 -166 5 -135 13 -155 68 -155 49 0 67 33 72 134 8 161 -48 306
-163 421 -75 75 -183 137 -262 150 -37 6 -46 3 -68 -19z"
          />
          <path
            d="M6214 6445 c-17 -26 -13 -66 9 -86 6 -6 37 -21 67 -32 82 -33 112
-81 120 -193 4 -69 9 -89 25 -100 23 -17 36 -18 69 -2 68 30 57 217 -18 313
-42 54 -62 71 -123 100 -69 34 -127 34 -149 0z"
          />
          <path
            d="M6192 6230 c-32 -13 -52 -66 -40 -102 12 -34 44 -58 76 -58 34 0 82
52 82 87 0 52 -67 94 -118 73z"
          />
          <path
            d="M4570 4929 c-5 -19 -12 -51 -16 -70 l-6 -34 21 25 c11 14 21 28 21
31 0 12 67 39 94 39 l26 0 0 -189 c0 -184 -1 -189 -22 -204 -22 -15 -18 -16
67 -16 85 0 89 1 68 16 -22 15 -23 20 -23 204 0 183 1 189 20 189 36 0 86 -27
114 -61 l28 -34 -6 34 c-4 19 -11 51 -16 70 l-9 36 -176 0 -176 0 -9 -36z"
          />
          <path
            d="M5040 4959 c-47 -3 -77 -6 -68 -7 31 -4 38 -38 38 -192 0 -105 4
-161 14 -184 20 -48 73 -70 168 -70 71 -1 79 1 115 30 53 43 62 77 62 240 1
142 6 165 41 177 8 3 -19 6 -60 6 -71 1 -73 0 -52 -15 23 -16 23 -19 20 -180
-3 -151 -5 -165 -24 -185 -27 -27 -102 -36 -145 -19 -49 21 -59 58 -59 218 0
139 6 164 40 174 31 9 -9 13 -90 7z"
          />
          <path
            d="M6185 4956 c-83 -36 -145 -130 -145 -220 0 -62 30 -126 82 -176 94
-92 269 -74 355 37 54 68 65 135 38 214 -36 102 -121 159 -234 159 -37 -1 -79
-7 -96 -14z m172 -62 c67 -51 100 -178 65 -256 -54 -122 -204 -121 -265 2 -31
63 -32 185 -1 227 48 66 134 78 201 27z"
          />
          <path
            d="M6997 4901 c-10 -35 -15 -66 -13 -68 2 -2 14 10 26 28 25 34 74 59
115 59 l25 0 0 -184 0 -184 -27 -21 -28 -20 95 0 c76 0 91 2 75 11 -11 6 -23
16 -27 22 -4 6 -8 93 -8 194 l0 182 25 0 c38 0 104 -37 117 -65 11 -25 34 -36
25 -12 -2 6 -11 37 -20 67 l-16 55 -174 0 -174 0 -16 -64z"
          />
          <path
            d="M4128 4943 c13 -11 43 -80 75 -173 82 -232 81 -229 67 -245 -11 -13
-3 -15 59 -15 41 0 71 4 71 10 0 6 -4 10 -10 10 -18 0 -10 31 50 193 75 197
87 225 108 230 10 3 -14 5 -53 5 -38 0 -64 -2 -57 -5 19 -7 14 -61 -12 -133
-13 -36 -37 -100 -53 -143 l-29 -77 -54 166 c-53 161 -54 166 -36 180 18 12
11 14 -63 14 -80 0 -83 -1 -63 -17z"
          />
          <path
            d="M5662 4953 c33 -8 40 -55 36 -233 -3 -169 -3 -171 -28 -190 l-25 -20
88 0 c51 0 87 4 87 10 0 6 -7 10 -15 10 -20 0 -27 33 -23 115 l3 69 67 26
c145 55 204 135 143 195 -24 25 -27 25 -187 24 -90 -1 -156 -3 -146 -6z m256
-49 c31 -20 28 -48 -10 -80 -27 -22 -108 -64 -124 -64 -3 0 -4 35 -2 77 3 73
4 78 28 84 32 8 79 1 108 -17z"
          />
          <path
            d="M6598 4944 c21 -15 22 -20 22 -199 0 -191 -3 -215 -26 -215 -8 0 -14
-4 -14 -10 0 -6 33 -10 80 -10 44 0 80 2 80 5 0 3 -9 14 -20 25 -16 16 -20 33
-20 93 0 39 4 78 8 85 5 7 24 15 43 18 33 5 36 3 74 -57 56 -87 67 -121 49
-148 -15 -21 -14 -21 60 -21 42 0 76 3 76 6 0 3 -8 13 -19 22 -10 10 -26 39
-36 66 -10 26 -36 76 -59 110 l-42 63 43 37 c36 32 43 43 43 75 0 66 -13 71
-201 71 -151 0 -162 -1 -141 -16z m225 -25 c31 -8 37 -14 37 -38 0 -15 -9 -37
-20 -49 -26 -27 -92 -62 -120 -62 -18 0 -20 6 -20 63 0 71 6 83 40 90 41 7 44
7 83 -4z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
